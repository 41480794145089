import { AgentClubStaffInvitationStatusEnum } from 'api/agency/player/shared/shared-models'
import { FormattedPriceModel } from 'api/core/formatted-price-model'

export interface Dashboard {
    playerVerifications: Array<PlayerVerification>
    clubStaffVerifications: Array<CoachVerification>
    playerPitchesCount: number
    totalPlayersCount: number
    totalClubStaffCount: number
    countOfVerifiedPlayers: number
    countOfPremiumVerifiedPlayers?: number
    recommendationFlags: RecommendationFlags
    invitedClubs: Array<InvitedClubInfo>;
    playerCreditAvailable?: number;
    regularPlayerCreditAvailable?: number;
    connectedToAgencyClubs?: Array<number>;
    revokedAccessClubs?: Array<number>;
    countOfPitchOpportunities: number;
    playersWithOpportunities: Array<PlayerPitchOpportunity>;
    countOfAllActiveSquads: number;
    totalOpportunitiesWithDeclined?: number;
    countOfPreconnectedPlayers?: number;
    totalPremiumPlayersCount?: number;
    totalPlayersWithSharedInsights?: number;
    playerMarketRequested?: boolean;
    showReonboardingBanner?: boolean;
}

export interface LiteCountersResponse {
    opportunityPitchCountIncludeDeclined: number;
    opportunityPitchCountExcludeDeclined: number;
}

export interface PlayerPitchOpportunity {
    playerId: number;
    playerName: string;
    playerSquad: IdName;
    positionCode: string;
    opportunityAds: Array<PlayerAdModel>;
    recomendedAdFee: { [key: number]: number };
}

export interface IdName {
    id: number;
    name: string;
    shortName: string;
}

export interface SquadShortInfo {
    id: number;
    name: string;
    shortName: string;
    squadLogo: string;
}

export interface PlayerAdModel {
    id: number;
    updatedAt: Date | string;
    amount: FormattedPriceModel;
    sellOn: number | null;
    grossSalary: FormattedPriceModel;
    positionCode: string;
    positionName: string;
    type: PlayerAdTypeEnumModel;
    currency: CurrencyModel;
    minHeight: number | null;
    fromAge: number | null;
    toAge: number | null;
    updateAtFormatted: string;
    isOutdated: boolean;
    isUpdatable: boolean;
    createdBySquad: SquadShortInfo;
}

export enum PlayerAdTypeEnumModel {
    Buy = 1,
    Loan = 2,
}

export interface CurrencyModel {
    id: number;
    name: string;
    sign: string;
}

export class InvitedClubInfo {
    id: number;
    logo: string;
    league: string;
    name: string;
    country: string;
}

export interface PlayerVerification {
    verifiedStatus: PlayerVerificationItemStatusEnum
    playerId: number
    playerName: string
    squadName: string
    squadId: number
    verificationExpirationInDays: number
    isHighInterest: boolean;
    isRegular: boolean;
}

export interface CoachVerification {
    clubStaffId: number;
    clubStaffName: string;
    isHighInterest: boolean;
    isRegular: boolean;
    squadId: number;
    squadName: string;
    verificationExpirationInDays: number;
    verifiedStatus: AgentClubStaffInvitationStatusEnum;
}

export class PlayersVerificationResponse {
    networkStrength: number;
    playerCredits: number;
    priorityCreditsRemaining: number;
    priorityPlayerCreditSwaps: number;
    annualCreditSwapWindowDaysLeft: number;
    isAnnualCreditSwapWindowActive: boolean;
    nextSwapWindowMessage: string;
    isUnlimitedCreditSwaps: boolean;
    isUnlimitedRegularSlots: boolean;
    showAnnualCreditSwapBanner: boolean;
    priorityPlayersCount: number;
    totalPlayersCount: number;
    totalPrioritySlots: number;
    playerInvitationsSent: Array<PlayerVerificationExtended>;
    players: Array<PlayerVerificationExtended>;
    hasShareInsightPermission: boolean;
}

export interface PlayerVerificationExtended {
    playerId: number
    playerName: string;
    age: number;
    isFreeAgent: boolean;
    promoText: string;
    firstPositionCode: string,
    secondaryPositionCode: string,
    photo: string;
    playerVideos: Array<PlayerVideo>;
    squadName: string;
    squadId?: number;
    verifiedStatus: PlayerVerificationItemStatusEnum;
    parentClubContractExpiry: Date;
    parentClubContractExpiryPlus: number;
    strategy: PlayerStrategyModel;
    isRegular: boolean;
    insightsShared: boolean;
    isFreePlusPitch: boolean;
}

export class PlayerStrategyModel {
    public plan: StrategyPlanModel;
    public currency: any;
    public leaguesCount: number;
}

export class StrategyPlanModel {
    public playerId: number;

    public isPlanningTransfer: boolean;

    public isPlanningLoan: boolean;

    public transferFeeFrom: number;

    public transferFeeTo: number;

    public grossSalaryFrom: number;

    public grossSalaryTo: number;
    public loanBudgetFrom: number;

    public loanBudgetTo: number;

    public strategySquadSet: Array<number>;
}

export interface PlayerVideo {
    url: string;
    type: PlayerVideoTypeEnum;
}

export enum PlayerVideoTypeEnum {
    ClubVideo = 1,
    AgentVideo = 2
}

export interface RecommendationFlags {
    addPlayers: boolean
    chooseYourSubscription: boolean
    connectWithClubs: boolean
    getVerified: boolean
    inviteAllYourColleagues: boolean
    eventRegister: boolean
}

export enum PlayerVerificationItemStatusEnum {
    PreconnectedToAgent = 1,
    DisconnectedFromAgent = 2,
    SentToPlayer = 3,
    VerifiedByPlayer = 4,
    VerifiedByAdmin = 5,
    RejectedByAdmin = 6,
}

export interface AgentUser {
    id: number
    name: string
    title: string
    licences: number
    assignedPlayers: number
    unVerifiedPlayers: number
    lastPitchedDays?: number
    isPending: boolean
}

export interface ClubConnectionsResponse {
    clubs: Array<ClubConnection>
    leagues: Array<LeagueConnection>
}

export interface ClubConnection {
    id: number
    squadName: string
    country: string
    league: string
}

export interface LeagueConnection {
    id: number
    leagueName: string
    country: string
    clubsCount: string
}

export enum PlusPitchSessionTypeEnum {
    Player = 1,
    HeadCoach = 2
}

